@import "includes/base";
// @import "includes/bs-grid/grid";
@import "includes/bs-vars-override";
@import "includes/bootstrap/bootstrap";

@import "includes/variables";
@import "includes/mixins";
@import "includes/typography";

@import "includes/components/modules";

a {
  color: $black;
  text-decoration: underline;
  transition: opacity 0.3s;

  &:hover {
    color: $black;
    opacity: 0.6;
  }

  &.icon {
    display: inline-flex;
    text-decoration: none;

    &:before {
      content: "";
      background: url("../img/ico_arrow.svg") no-repeat center;
      width: 40px;
      height: 40px;
      display: inline-block;
      margin-right: 0.75rem;
      position: relative;
      bottom: 4px;
    }

    &.download {
      &:before {
        margin-bottom: 2rem;
        transform: rotate(90deg);
      }
    }
  }
}

section {
  padding: 100px 0;

  // &.parallax {
  //   margin: 0;
  // }

  &#welcome,
  &#vorteile {
    p {
      padding-top: 1.75rem;
    }
  }
  &#impressum {
    .h4 {
      padding-top: 1.75rem;
    }
  }

  &#team,
  &#notar {
    .card-title {
      font-family: "Montserrat", Helvetica, Arial, sans-serif;
      font-size: 1.125rem;
      margin-bottom: 0;
    }
    p {
      padding-top: 0;
    }

    ul {
      list-style-position: outside;
      list-style: none;
      padding-left: 10px;
      list-style-type: "- ";
    }
  }
  &#leistungen {
    .contact {
      margin-bottom: 3rem;
    }
    @include media-breakpoint-up(md) {
      .contact {
        margin-top: 5rem;
      }
    }
  }
}

.block-cover-image {
  position: relative;
  background-size: contain;
  background-position: 80%;
  min-height: 430px;
  width: 100%;
  // margin: 0 0 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
  background-repeat: no-repeat;

  @media (max-width: 373.98px) {
    &.img1 {
      background-image: url(../img/Karl-Heinz-Klett2_sm.jpg);
      min-height: 240px;
    }
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    &.img1 {
      background-image: url(../img/Karl-Heinz-Klett2_sm@2x.jpg);
    }
  }

  @media (min-width: 374px) {
    &.img1 {
      background-image: url(../img/Karl-Heinz-Klett2_md.jpg);
    }
  }
  @include media-breakpoint-up(md) {
    &.img1 {
      background-image: url(../img/Karl-Heinz-Klett2_lg.jpg);
    }
  }
  @media (min-width: 1360px) {
    &.img1 {
      background-image: url(../img/Karl-Heinz-Klett2_lg@2x.jpg);
    }
  }

  @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 800px),
    (min-resolution: 192dpi) and (min-width: 800px) {
    &.img1 {
      background-image: url(../img/Karl-Heinz-Klett2_md@2x.jpg);
    }
  }
  @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 1200px),
    (min-resolution: 192dpi) and (min-width: 1200px) {
    &.img1 {
      background-image: url(../img/Karl-Heinz-Klett2_lg@2x.jpg);
    }
  }

  @media (max-width: 373.98px) {
    &.img2 {
      background-image: url(../img/standort_sm.jpg);
      min-height: 240px;
    }
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    &.img2 {
      background-image: url(../img/standort_sm@2x.jpg);
    }
  }

  @media (min-width: 374px) {
    &.img2 {
      background-image: url(../img/standort_md.jpg);
    }
  }
  @include media-breakpoint-up(md) {
    &.img2 {
      background-image: url(../img/standort_lg.jpg);
    }
  }
  @media (min-width: 1360px) {
    &.img2 {
      background-image: url(../img/standort_lg@2x.jpg);
    }
  }

  @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 800px),
    (min-resolution: 192dpi) and (min-width: 800px) {
    &.img2 {
      background-image: url(../img/standort_md@2x.jpg);
    }
  }
  @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 1200px),
    (min-resolution: 192dpi) and (min-width: 1200px) {
    &.img2 {
      background-image: url(../img/standort_lg@2x.jpg);
    }
  }
}

// .vorteile {
//   .parallax {
//     background: $white url("../img/Karl-Heinz-Klett-2@2x.jpg") no-repeat center;
//     width: 100%;
//     height: 100vh;
//     background-size: cover;
//     background-attachment: fixed;
//     position: relative;

//     // width: 100%;
//     // height: 100vh;
//     // background-size: cover;
//     // background-attachment: fixed;
//     // background-position: center;
//     // position: relative;

//     display: flex;
//     align-items: center;
//   }
// }

.parallax {
  background: $white url("../img/Karl-Heinz-Klett@2x.jpg") no-repeat center;
  // width: 100%;
  // height: 100vh;
  // background-size: cover;
  // background-attachment: fixed;
  // position: relative;

  width: 100%;
  height: 100vh;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  position: relative;

  display: flex;
  align-items: center;
}

.grey_bg {
  background-color: $grey;
}

.unterschrift {
  padding-left: 1rem;
}

.logo_bw {
  display: inline-flex;
  margin-bottom: 3rem;

  img {
    // margin-top: 1.5rem;
    // margin-right: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    // min-width: 5rem;

    margin-right: 1rem;

    @include media-breakpoint-up(md) {
      margin-right: 2rem;
    }
  }
}
