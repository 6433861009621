header {
  transition: all 0.4s ease;

  padding-top: 82px;

  @include media-breakpoint-up(lg) {
    padding-top: 226px;

    .navbar-brand {
      padding-top: 2.3125rem;
      padding-bottom: 2.3125rem;
    }
  }

  &.small {
    padding-top: 82px;

    .logo {
      width: 120px;
    }

    @include media-breakpoint-up(lg) {
      .navbar-brand {
        padding-top: 0.3125rem;
        padding-bottom: 0.3125rem;

        // padding-top: 2.3125rem;
        // padding-bottom: 2.3125rem;
      }
    }
  }
  nav.navbar {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

    a {
      text-decoration: none;
    }
    @include media-breakpoint-down(md) {
      .container {
        flex-direction: row !important;
      }
    }

    &.navbar-light .navbar-nav {
      margin-top: 1.25rem;

      @include media-breakpoint-up(md) {
        margin-top: 0rem;
      }

      .nav-link {
        color: $black;
        font-weight: 300;
        font-size: 1.125rem; // 18px
        padding-right: 1.5rem;

        @include media-breakpoint-up(md) {
          padding-left: 1.5rem;
        }
      }
    }

    .logo {
      transition: all 0.4s ease;

      @include media-breakpoint-down(md) {
        width: 120px;
      }

      &-small {
        width: 120px;
      }
    }
  }
}

footer {
  background-color: $grey;
  padding: 100px 0 60px 0;
  font-size: 1rem;

  h3 {
    font-weight: 600;
    font-size: 1rem;
    line-height: 27px;
  }

  .logo {
    opacity: 0.1;
  }

  a {
    text-decoration: none;
  }

  nav {
    .list-group-item {
      border: 0;
      background-color: transparent;
      font-size: 0.75rem; //12px;
      line-height: 28px;
      padding: 0.25rem 1.25rem;
    }
  }
}

.card {
  &.leistung {
    // background-color: $white;
    border-left: 6px solid $blue-light;
    margin-bottom: 2rem;

    @include media-breakpoint-up(xl) {
      min-height: 300px;
    }
  }

  &.team {
    @include media-breakpoint-up(xl) {
      min-height: 115px;

      .card-body {
        padding: 1.25rem 1.25rem 0 1.25rem;
      }
    }

    .card-title {
      font-weight: bold;
    }

    .card-body {
      padding: 1.25rem 1.25rem 0.5rem 1.25rem;
    }
  }
}

.fixed_div {
  position: fixed;
  right: 0;
  top: calc(50% - 67px);
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 68px;
  height: 134px;
  z-index: 10;

  .ico {
    margin: 0 auto;
    background-color: $grey-lighter;
    width: 68px;
    height: 62px;
    // border: 1px solid red;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    border-left: 7px solid $grey-dark;

    // img {
    //   border: 1px solid #000000;
    // }

    &_map {
      width: 27px;
      height: 40px;
    }

    &_contact {
      top: 10px;
      width: 41px;
      height: 41px;
    }
  }
}
