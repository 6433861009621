/* || ################################## BEGIN Typography ################################## */
//  Mobile, XS and SM

h1,
.h1,
h2,
.h2,
h5,
.h5 {
  font-family: "STIXGeneral", Helvetica, Arial, sans-serif;
}

h1,
.h1,
h2,
.h2 {
  font-weight: normal;
  font-size: 2.125rem;
  line-height: 1.875rem; // 46px;
  border-left: 6px solid $blue-light;
  padding: 1rem;
}

h3,
.h3 {
  font-family: "Helvetica Neue LT Condensed", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 1.5rem; //24px;
  letter-spacing: 0.03em;
  line-height: 1.875rem; //30px;

  margin-top: 10px;
}
h4,
.h4 {
  font-family: "Helvetica Neue LT Condensed", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 1.0625rem; //17px;
  line-height: 1.3125rem; //21px;
}
h5,
.h5 {
  font-size: 1rem;
  line-height: 1rem;
}

@include media-breakpoint-up(md) {
  h1,
  .h1,
  h2,
  .h2 {
    font-size: 2.375rem; // 38px;
    line-height: 2.875rem; // 46px;
  }
  h3,
  .h3,
  .module11 h2,
  .module12 h2 {
    margin-top: 15px;
  }

  h4,
  .h4 {
    font-size: 1.1875rem; //19px;
    line-height: 1.625rem; //26px;
  }

  h5,
  .h5 {
    font-size: 1.5rem; //24px;
    line-height: 1.625rem; //26px;
  }
}

/* || ################################## END Typography ################################## */
