@import "variables";
@import "bs-vars-override";
@import "bootstrap/bootstrap";

/* montserrat-300 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../fonts/montserrat-v15-latin-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../fonts/montserrat-v15-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
/* montserrat-600 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/montserrat-v15-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
/* montserrat-800 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../fonts/montserrat-v15-latin-800.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/montserrat-v15-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

@font-face {
  font-family: "STIXGeneral";
  font-style: normal;
  src: url("../fonts/STIXGeneral.otf") format("opentype");
  font-display: swap;
}

a {
  color: $link;
  transition: color 200ms;
  &:hover {
    color: darken($link, 20);
  }
}

@each $name, $value in $colorList {
  .has-background-#{$name} {
    background-color: $value;
  }
}

@each $name, $value in $fontColors {
  .has-text-#{$name} {
    color: $value;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  max-width: 100%;
  // overflow-x: hidden;
  font-family: $font-family-base;

  font-size: 1rem; //16px;
  line-height: 1.5625rem; //25px;
  color: $black;
  height: 100%;
}

body {
  // padding-top: 70px;

  @include media-breakpoint-up(lg) {
    font-size: 1rem !important; //19px;
    line-height: 2rem !important; //32px;
  }

  @include media-breakpoint-up(xl) {
    // padding-top: 155px;
  }
}

*,
*:after,
*:before {
  box-sizing: border-box;
}
