$grid-gutter-width: 16px;
$body-color: #333333;

$font-family-base: "Montserrat", Helvetica, Arial, sans-serif;
$font-size-base: 1rem;

$font-size-lg: 1rem;
$font-size-sm: $font-size-base * 0.875;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1448px,
);

// Buttons
$btn-border-width: 0;
$input-btn-padding-y: 0.75rem;
$input-btn-padding-x: 0.75rem;

// $btn-link-disabled-color: $gray-600;

// Cards
$card-bg: transparent;
$card-cap-bg: transparent;
$card-border-width: 0;
$card-border-radius: 0;

// breadcrumb
$breadcrumb-bg: $white;
$breadcrumb-padding-x: 0;
$breadcrumb-padding-y: 0;
$breadcrumb-divider: quote("|");
$breadcrumb-active-color: #222222;
$breadcrumb-divider-color: $primary;

// Modal
$modal-backdrop-bg: $white;
$modal-md: 100%;
$modal-backdrop-opacity: 0.85;

// ## Forms
$input-font-size: 0.875rem; //14px;
// Placeholder
$input-placeholder-color: $black;
