$orange: #dd5600;
$yellow: #ffdd57;
$green: #12a647;
$turquoise: #1a8184;
$turquoise-light: #29a6b9;

$blue: #3273dc;
$blue-light: #a1b6c7;
$purple: #7e5ea3;
$red: #f70032;
$cyan: #209cee;
$black: hsl(0, 0%, 4%);
$white: hsl(0, 0%, 100%);
$light: hsl(0, 0%, 96%);
$dark: hsl(0, 0%, 21%);

$white: #ffffff;
$grey: #edf1f4;
$grey-light: #dddddd;
$grey-lighter: #f8f8f8;
$grey-dark: #3f5364;
$cyan: #59add3;
$cyan-dark: #388ab0;
$cyan-darker: #1a4457;
$grey-light: #f4f4f4;
$red: #ee1100;
$black: #000000;

$primary: $turquoise;
$secondary: $turquoise-light;
$link: $turquoise-light;
$info: $cyan;
$success: $green;
$warning: $yellow;
$danger: $red;

$colorList: (
  black: $black,
  white: $white,
  light: $light,
  dark: $dark,
  primary: $primary,
  link: $link,
  info: $info,
  success: $success,
  warning: $warning,
  danger: $danger,
);

$fontColors: (
  black: $black,
  white: $white,
  light: $light,
  dark: $dark,
  primary: $primary,
  link: $link,
  info: $info,
  success: $success,
  warning: $warning,
  danger: $danger,
  purple: $purple,
  orenge: $orange,
);
